import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LocalStorageConstants } from './constants/LocalStorageConstants';

const ProtectedRoutes = ({ item }: { item: RouteProps }) => {
  const signedIn = localStorage.getItem(LocalStorageConstants.keys.token);

  // if (!signedIn) {
  //   return <Redirect to="/login" />;
  // } else {
  //   return <Route {...item} />;
  // }
  return <Route {...item} />;
};

export default ProtectedRoutes;
