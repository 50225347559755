import { Box, CssBaseline, Drawer, Typography } from '@mui/material';
import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { LocalStorageConstants } from '../constants/LocalStorageConstants';

const drawerWidth = 300;

const Default: React.FC = ({ children }) => {
  const location = useLocation();
  const navigator = useHistory();

  const handleSignout = () => {
    localStorage.setItem(LocalStorageConstants.keys.token, '');
    navigator.replace('/login');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#6FA8DC'
          }
        }}
        variant="permanent">
        <label className="text-6xl text-white font-bold p-10 items-center">Shipyy</label>
        <div className="flex flex-col justify-between h-full text-center">
          <div>
            <Link to={'/'}>
              <div
                className={`p-2 ${
                  location.pathname === '/' ? '' : 'hover:'
                }bg-primary-dark mt-2 mr-4 ml-4 rounded-md`}>
                <label className="text-xl text-white font-semibold items-center">Dashboard</label>
              </div>
            </Link>
            <Link to={'/define-task'}>
              <div
                className={`p-2 ${
                  location.pathname !== '/' ? '' : 'hover:'
                }bg-primary-dark mt-2 mr-4 ml-4 rounded-md`}>
                <label className="text-xl text-white font-semibold items-center">Create task</label>
              </div>
            </Link>
          </div>

          <div className="flex flex-col gap-2 mr-4 ml-4 mb-10 items-stretch justify-evenly">
            <div className="bg-primary-dark bg-opacity-20 rounded-md p-3">
              <label className="text-white font-bold p-10 items-center">
                {localStorage.getItem(LocalStorageConstants.keys.user_name)}
              </label>
            </div>

            <div className="bg-red bg-opacity-80 rounded-md p-3 hover:bg-opacity-100">
              <button className="text-xl text-white font-bold items-center" onClick={handleSignout}>
                Sign out
              </button>
            </div>
          </div>
        </div>
      </Drawer> */}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          p: 3,
          height: '100vh'
        }}>
        <div className="h-full w-full">{children}</div>
      </Box>
    </Box>
  );
};

export default Default;
