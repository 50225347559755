import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../redux/reducers';

const composeEnhancers =
  ((window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const saga = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(saga, thunk)));

export default store;
