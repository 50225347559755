import { combineReducers } from 'redux';

const appReducer = combineReducers({});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    state = action.payload;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
