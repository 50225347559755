import { useState } from 'react';
import TextField from './TextField';

type AddSelectionOptionComponentProps = {
  onAddClick: (name: string) => void;
};

const AddSelectionOptionComponent = (props: AddSelectionOptionComponentProps) => {
  const [optionName, setOptionName] = useState('');

  const onAddClick = () => {
    if (optionName.length <= 0) {
      return;
    }
    props.onAddClick(optionName);
    setOptionName('');
  };

  return (
    <div className="rounded-md mb-2 mt-2">
      <div className="flex flex-row gap-2 items-center">
        <div className="w-full">
          <TextField
            type="string"
            value={optionName}
            placeholder="Option name"
            onChange={(updatedText) => setOptionName(updatedText)}
          />
        </div>

        <div className="items-center">
          <button
            className="text-bold text-primary-light border border-solid border-primary-light pl-2 pr-2 hover:bg-primary-dark hover:text-white hover:border-primary-dark rounded "
            onClick={onAddClick}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSelectionOptionComponent;
