import { RouteProps } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import TaskDefinePage from './pages/TaskDefinePage';
import TaskDetailsPage from './pages/TaskDetailsPage';
import ConfigureEndpointPage from './pages/ConfigureEndpointPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';

export const PROTECTED_ROUTES: RouteProps[] = [
  {
    path: '/',
    component: DashboardPage,
    exact: true
  },
  {
    path: '/define-task',
    component: TaskDefinePage,
    exact: true
  },
  {
    path: '/task-detail',
    component: TaskDetailsPage,
    exact: true
  },
  {
    path: '/configure-endpoint',
    component: ConfigureEndpointPage,
    exact: true
  }
];

export const UNPROTECTED_ROUTES: RouteProps[] = [
  {
    path: '/login',
    component: LoginPage,
    exact: true
  },
  {
    path: '/signup',
    component: SignupPage,
    exact: true
  }
];

export const paths = PROTECTED_ROUTES.map((r) => r.path);
