import _ from 'lodash';
import { TaskUserFacingParam } from '../types/task';
import { StringUtility } from '../utility/StringUtility';
import SelectionComponent from './SelectionComponent';
import SwitchComponent from './SwitchComponent';
import TextField from './TextField';

type UserFacingParamComponentProps = {
  param: TaskUserFacingParam;
  onValueChange: (slug: string, updatedValue: any) => void;
  mapWithParamValue: Map<string, string>;
};

const UserFacingParamComponent = (props: UserFacingParamComponentProps) => {
  const param = props.param;
  const map = props.mapWithParamValue;

  return (
    <div key={param._id} className="mt-4 w-full">
      {param.paramType == 'boolean' && (
        <SwitchComponent
          title={_.startCase(param.paramName)}
          required={param.isRequired}
          onChange={(checked) => props.onValueChange(param.slug, checked)}
        />
      )}

      {param.paramType == 'string' && (
        <TextField
          type={param.paramType}
          title={_.startCase(param.paramName)}
          required={param.isRequired}
          value={`${map.get(param.slug)}`}
          onChange={(updatedText) => props.onValueChange(param.slug, updatedText)}
        />
      )}

      {param.paramType == 'number' && (
        <TextField
          type={param.paramType}
          title={_.startCase(param.paramName)}
          required={param.isRequired}
          onChange={(updatedText) => props.onValueChange(param.slug, updatedText)}
        />
      )}

      {/* {(param.paramType == 'string' || param.paramType == 'number') && param.hasSelectOptions && (
        <SelectionComponent
          title={param.paramName}
          required={param.isRequired}
          options={param.selectOptions}
          onChange={(value) => props.onValueChange(param.slug, value)}
        />
      )} */}
    </div>
  );
};

export default UserFacingParamComponent;
