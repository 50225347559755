import axios from 'axios';

export interface GenerateClientAccessTokenResponse {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
}

export class AuthService {
  // access token
  static async generateClientAccessToken() {
    const { data } = await axios.post<GenerateClientAccessTokenResponse>(
      '/credential/v1/credential/vendor-credential/access-token',
      {
        clientId: '6e5d420uvmjn1v1vcsikindakf',
        clientSecret: 'hjjupj24sh9321tg08mfrbslnhfalqm57v8psa8gn9qgdtrh4pe'
      }
    );

    return data;
  }
}
