import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './index.css';
import App from './App';
import { store } from './store';
import Default from './layout/Default';
import { PROTECTED_ROUTES, UNPROTECTED_ROUTES } from './routes';
import ProtectedRoutes from './ProtectedRoutes';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          {UNPROTECTED_ROUTES.map((item, index) => (
            <Route {...item} key={index} />
          ))}
          <Default>
            {PROTECTED_ROUTES.map((item, index) => (
              <ProtectedRoutes item={item} key={index} />
            ))}
          </Default>
        </Switch>
      </Router>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
