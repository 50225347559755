import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  ConfigureEndPointProps,
  endpointMethods,
  Task,
  TaskUrlHeader,
  TaskUrlParam
} from '../types/task';
import axios from 'axios';

const ConfigureEndpointPage = () => {
  const reactNavigator = useHistory();
  const location = useLocation();

  const props = location.state as ConfigureEndPointProps;

  const slugs: string[] = props.userFacingParams?.map((param) => param.slug) || [];

  const [taskUrlParams, setTaskUrlParams] = useState<TaskUrlParam[]>([]);
  const [taskUrlHeaders, setTaskUrlHeaders] = useState<TaskUrlHeader[]>([]);
  const [taskJsonBody, setTaskJsonBody] = useState('');
  const [baseUrl, setBaseUrl] = useState('https://prod-api.vegapay.tech');
  const [endpoint, setEndpoint] = useState('');
  const [endPointType, setEndpointType] = useState<endpointMethods>('get');

  // Params
  const handleAddTaskUrlParam = () => {
    const urlParamObject: TaskUrlParam = {
      id: nanoid(),
      name: '',
      value: ''
    };

    setTaskUrlParams((prev) => [...prev, urlParamObject]);
  };

  const handleNameChangeForURLParam = (id: string, name: string) => {
    const index = taskUrlParams.findIndex((value) => value.id === id);
    const tempArray = [...taskUrlParams];
    tempArray[index] = { ...tempArray[index], name: name };
    setTaskUrlParams(tempArray);
  };

  const handleValueChangeForURLParam = (id: string, value: string) => {
    const index = taskUrlParams.findIndex((value) => value.id === id);
    const tempArray = [...taskUrlParams];
    tempArray[index] = { ...tempArray[index], value: value };
    setTaskUrlParams(tempArray);
  };

  const handleRemoveTaskUrlParam = (id: string) => {
    const tempParams = taskUrlParams.filter((param) => param.id !== id);
    setTaskUrlParams(tempParams);
  };

  // Headers
  const handleAddTaskHeader = () => {
    const urlHeader: TaskUrlHeader = {
      id: nanoid(),
      name: '',
      value: ''
    };

    setTaskUrlHeaders((prev) => [...prev, urlHeader]);
  };

  const handleNameChangeForHeader = (id: string, name: string) => {
    const index = taskUrlHeaders.findIndex((value) => value.id === id);
    const tempArray = [...taskUrlHeaders];
    tempArray[index] = { ...tempArray[index], name: name };
    setTaskUrlHeaders(tempArray);
  };

  const handleValueChangeForHeader = (id: string, value: string) => {
    const index = taskUrlHeaders.findIndex((value) => value.id === id);
    const tempArray = [...taskUrlHeaders];
    tempArray[index] = { ...tempArray[index], value: value };
    setTaskUrlHeaders(tempArray);
  };

  const handleRemoveUrlHeader = (id: string) => {
    const tempParams = taskUrlHeaders.filter((param) => param.id !== id);
    setTaskUrlHeaders(tempParams);
  };

  const handleBackClick = () => {
    reactNavigator.goBack();
  };

  const copySlugToClipboard = (slug: string) => {
    navigator.clipboard.writeText(slug);
  };

  const handleCreateTaskClick = async () => {
    const headerObject = {} as any;

    taskUrlHeaders.forEach((item) => {
      headerObject[item.name] = item.value;
    });

    const urlParamObject = {} as any;

    taskUrlParams.forEach((item) => {
      urlParamObject[item.name] = item.value;
    });

    const task: Task = {
      id: nanoid(),
      name: props.taskName,
      description: props.taskDescription,
      userFacingParams: props.userFacingParams,
      urlParams: urlParamObject,
      urlHeaders: headerObject,
      endpointMethodType: endPointType,
      endpointBaseUrl: baseUrl,
      endpoint: endpoint
    };

    if (taskJsonBody.length > 0) {
      task.endpointJSONBody = JSON.parse(taskJsonBody);
    }

    const result = await axios.post('/los/v3/api-playground/task', task);
    console.log(result);
    reactNavigator.replace('/');
  };

  return (
    <div className="flex flex-row">
      <div className="w-full justify-end p-8 min-w-2/3 mt-10">
        {/* Heading */}
        <label className="font-bold text-5xl ">Step 2/2 Configure endpoint</label>

        {/* Path */}
        <div className="flex flex-row items-center gap-3 mt-10">
          <div>
            <select
              className="form-select appearance-none block px-3 py-1.5 bg-white border border-border-color rounded text-center"
              aria-label="Default select example"
              onChange={(e) => setEndpointType(e.target.value as endpointMethods)}>
              <option value="get">GET</option>
              <option value="put">PUT</option>
              <option value="post">POST</option>
              <option value="delete">DELETE</option>
              <option value="patch">PATCH</option>
            </select>
          </div>

          <input
            type="text"
            className=" w-full rounded border sm:text-sm border-border-color px-3 py-1.5"
            placeholder="https://staging.myserver.in"
            value={baseUrl}
            onChange={(e) => setBaseUrl(e.target.value)}
          />

          <input
            type="text"
            className="rounded-md border sm:text-sm border-border-color px-3 py-1.5"
            placeholder="/api/v1/user"
            onChange={(e) => setEndpoint(e.target.value)}
          />
        </div>

        {/* Url params list */}
        <div className="flex flex-col justify-between items-left mt-10 ">
          <label className="text-lg block text-md font-medium text-black">URL Parameters</label>
          {taskUrlParams.map((param) => (
            <div className="flex flex-row gap-5 mt-5 w-full" key={param.id}>
              <div>
                <input
                  type="text"
                  className=" w-full rounded border sm:text-sm border-border-color px-3 py-1.5"
                  placeholder="Name"
                  onChange={(e) => handleNameChangeForURLParam(param.id, e.target.value)}></input>
              </div>

              <div>
                <input
                  type="text"
                  className=" w-full rounded border sm:text-sm border-border-color px-3 py-1.5"
                  placeholder="Value"
                  onChange={(e) => handleValueChangeForURLParam(param.id, e.target.value)}></input>
              </div>

              <div>
                <button
                  className="rounded bg-white border border-red text-red px-2 py-1 hover:bg-opacity-40 hover:bg-red hover:text-white hover:border-opacity-40"
                  onClick={() => handleRemoveTaskUrlParam(param.id)}>
                  Remove
                </button>
              </div>
            </div>
          ))}
          <span>
            <button
              className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white mt-4"
              onClick={handleAddTaskUrlParam}>
              + Add Parameters
            </button>
          </span>
        </div>

        {/* URl headers */}
        <div className="flex flex-col justify-between items-left mt-10 ">
          <label className="text-lg block text-md font-medium text-black">Headers</label>
          {taskUrlHeaders.map((header) => (
            <div className="flex flex-row gap-5 mt-5 w-full" key={header.id}>
              <div>
                <input
                  type="text"
                  className=" w-full rounded border sm:text-sm border-border-color px-3 py-1.5"
                  placeholder="Name"
                  onChange={(e) => handleNameChangeForHeader(header.id, e.target.value)}></input>
              </div>

              <div>
                <input
                  type="text"
                  className=" w-full rounded border sm:text-sm border-border-color px-3 py-1.5"
                  placeholder="Value"
                  onChange={(e) => handleValueChangeForHeader(header.id, e.target.value)}></input>
              </div>

              <div>
                <button
                  className="rounded bg-white border border-red text-red px-2 py-1 hover:bg-opacity-40 hover:bg-red hover:text-white hover:border-opacity-40"
                  onClick={() => handleRemoveUrlHeader(header.id)}>
                  Remove
                </button>
              </div>
            </div>
          ))}
          <span>
            <button
              className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white mt-4"
              onClick={handleAddTaskHeader}>
              + Add Header
            </button>
          </span>
        </div>

        {/* Body */}
        <div className="w-full mt-10">
          <textarea
            rows={5}
            className=" mt-1 block w-full sm:text-sm border border-border-color rounded p-2"
            placeholder="{ }"
            onChange={(e) => setTaskJsonBody(e.target.value)}
          />
        </div>

        {/* Buttons */}
        <div className="mt-12 flex flex-row items-center justify-between">
          <button
            className="px-4 rounded border-2 border-solid border-primary-light text-primary-dark hover:bg-opacity-20 hover:bg-primary-dark hover:border-opacity-20"
            onClick={handleBackClick}>
            Back
          </button>
          <button
            className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white"
            onClick={handleCreateTaskClick}>
            Create Task
          </button>
        </div>
      </div>

      {/* vertical border */}
      <div className="border border-r border-primary-dark border-dotted"></div>

      {/* Available slugs */}
      <div className="flex flex-col gap-5 mt-10 p-8 w-1/2">
        <label className="font-semibold text-4xl">Available Slugs</label>
        {slugs.map((item) => (
          <div
            key={item}
            className="border p-2 rounded-md border-primary-dark border-solid flex flex-row justify-between items-center">
            <label className="text-primary-dark text-sm">{item}</label>
            <button
              className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white"
              onClick={() => copySlugToClipboard(item)}>
              Copy
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConfigureEndpointPage;
