import { Switch } from '@mui/material';

type SwitchProps = {
  title?: string;
  required?: boolean;
  description?: string;
  defaultValue?: boolean;
  onChange?: (checked: boolean) => void;
};

const SwitchComponent = (props: SwitchProps) => {
  return (
    <div className="flex flex-col w-full mt-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center">
          <label className="text-bold text-lg"> {props.title} </label>
          {props.required && <label className="text-red text-xl">*</label>}
        </div>

        <Switch
          checked={props.defaultValue}
          onChange={(e, checked) => {
            if (props.onChange) {
              props.onChange(checked);
            }
          }}
        />
      </div>

      <label className="text-sm "> {props.description} </label>
    </div>
  );
};

export default SwitchComponent;
