import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AddTaskParamDialog from '../components/AddTaskParamDialog';
import { TaskUserFacingParam } from '../types/task';

const TaskDefinePage = () => {
  const navigator = useHistory();
  const [showAddParamDialog, setShowAddParamDialog] = useState(false);
  const [userFacingParams, setUserFacingParams] = useState<TaskUserFacingParam[]>([]);
  const [taskName, setTaskName] = useState('');
  const [taskDescription, setTaskDescription] = useState('');

  const handleAddParamClick = (result: TaskUserFacingParam) => {
    setShowAddParamDialog(false);
    setUserFacingParams((prev) => [...prev, result]);
  };

  const handleRemoveParamClick = (id: string) => {
    const tempParams = userFacingParams.filter((param) => param._id !== id);
    setUserFacingParams(tempParams);
  };

  return (
    <div className="w-2/3 justify-end p-8 min-w-2/3 ">
      {/* Heading */}

      <label className="font-bold text-5xl ">Step 1/2 Define your task</label>

      {/* Task Name */}
      <div>
        <label htmlFor="about" className="block text-md font-medium text-black mt-10">
          Task name
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="taskName"
            id="taskName"
            required
            className=" mt-1 block w-full sm:text-sm border border-border-color rounded-md p-2"
            placeholder="Task name"
            onChange={(e) => setTaskName(e.target.value)}
          />
        </div>
      </div>

      {/* Short Description */}
      <div className="mt-4">
        <label htmlFor="about" className="block text-md font-medium text-black">
          Short Description
        </label>
        <div className="mt-1">
          <textarea
            id="about"
            name="about"
            rows={3}
            className=" mt-1 block w-full sm:text-sm border border-border-color rounded-md p-2"
            onChange={(e) => setTaskDescription(e.target.value)}
          />
        </div>
        <p className="mt-2 text-sm text-gray-500">Brief description for your task.</p>
      </div>

      {/* Add Params */}
      <div className="mt-4 flex flex-row items-center justify-between">
        <div>
          <label htmlFor="parameters" className="block text-md font-medium text-black">
            Parameters
          </label>
          <p className="text-sm text-gray-200">
            Parameters define what users are asked to input into this task
          </p>
        </div>

        <div>
          <button
            className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white"
            onClick={() => setShowAddParamDialog(true)}>
            Add
          </button>
        </div>
      </div>

      {/* Task params list */}
      <div className="mt-5">
        {userFacingParams.map((param) => (
          <div
            className="flex flex-row justify-between items-center border border-border-color p-3 rounded-md mt-3"
            key={param._id}>
            <div className="flex flex-row items-center justify-around">
              <label className="text-sm"> {param.paramName}</label>
              {param.isRequired && <p className="text-sm p-3 text-thin ">(Required)</p>}
            </div>

            <button
              className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white"
              onClick={() => handleRemoveParamClick(param._id)}>
              Remove
            </button>
          </div>
        ))}
      </div>

      {/* Cancel and configure endpoint  */}
      <div className="mt-12 flex flex-row items-center justify-between">
        <button
          className="px-4 rounded border-2 border-solid border-primary-light text-primary-dark hover:bg-opacity-20 hover:bg-primary-dark hover:border-opacity-20"
          onClick={() => navigator.goBack()}>
          Cancel
        </button>

        <Link
          to={{
            pathname: '/configure-endpoint',
            state: {
              taskName: taskName,
              taskDescription: taskDescription,
              userFacingParams: userFacingParams
            }
          }}>
          <button className="bg-primary-light px-4 py-1 rounded hover:bg-primary-dark shadow-md text-white">
            Configure Endpoint
          </button>
        </Link>
      </div>

      {showAddParamDialog && (
        <AddTaskParamDialog
          isOpen={showAddParamDialog}
          onAddClick={(result) => handleAddParamClick(result)}
          onCancelClick={() => setShowAddParamDialog(false)}
        />
      )}
    </div>
  );
};

export default TaskDefinePage;
