import { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
// import { postRequest } from '../api_client';

const SignupPage = () => {
  const navigator = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  const handleSignup = async () => {
    // const data = await postRequest('/users/register', {
    //   user_name: name,
    //   user_email: email,
    //   password: password
    // });
    // navigator.replace('/login');
  };

  const handleLogin = () => {
    navigator.replace('/login');
  };

  return (
    <div className="h-screen flex flex-col text-center items-center justify-center bg-gray-background">
      {/* Title */}
      <div className="text-center flex flex-col gap-4 mb-20 font-semibold ">
        <label className="text-6xl text-bold text-center items-center">Welcome to Shipyy</label>
      </div>

      <div className="p-8 rounded-md shadow-2xl-primary-dark w-128 items-center align-middle bg-white">
        {/*  Login */}
        <div className="flex flex-col gap-2  items-center">
          <input
            type="text"
            placeholder="Name"
            className="rounded-md p-2 border-primary-dark border w-full"
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="email"
            required
            placeholder="email@shipyy.com"
            className="rounded-md p-2 border-primary-dark border w-full"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="Password"
            required
            className="rounded-md p-2 border-primary-dark border w-full"
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            className="text-white px-5 py-2 rounded-md bg-gradient-to-r from-primary-dark to-primary-light mt-4 w-full"
            onClick={() => handleSignup()}>
            Signup
          </button>
        </div>
      </div>
      <label className="px-5 py-2 rounded-md mt-4 w-full" onClick={() => handleLogin()}>
        Already have an account?{' '}
        <Link to={{ pathname: '/login' }} className="text-primary-dark">
          Sign in instead
        </Link>
      </label>
    </div>
  );
};

export default SignupPage;
