import { nanoid } from 'nanoid';
import './App.css';
import AddTaskParamDialog from './components/AddTaskParamDialog';
import Default from './layout/Default';
import ConfigureEndpointPage from './pages/ConfigureEndpointPage';
import DashboardPage from './pages/DashboardPage';
import TaskDefinePage from './pages/TaskDefinePage';
import TaskDetailsPage from './pages/TaskDetailsPage';
import { configureAxiosDefaults } from './axios_config';

function App() {
  configureAxiosDefaults();

  return <div></div>;
}

export default App;
