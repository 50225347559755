type TextFeildProps = {
  title?: string;
  value?: string;
  required?: boolean;
  placeholder?: string;
  description?: string;
  type: string;
  onChange?: (updatedValue: string) => void;
};

const TextField = (props: TextFeildProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center">
        <label className="text-bold text-lg"> {props.title} </label>
        {props.required && <label className="text-red text-xl">*</label>}
      </div>

      <input
        type={props.type}
        title={props.title}
        value={props.value}
        placeholder={props.placeholder}
        className="border-primary-dark border rounded-md p-2"
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }
        }}></input>
      <label className="text-sm "> {props.description} </label>
    </div>
  );
};

export default TextField;
