import { Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectOption, TaskUserFacingParam, UserFacingParamType } from '../types/task';
import { nanoid } from 'nanoid';
import SwitchComponent from './SwitchComponent';
import AddSelectionOptionComponent from './AddSelectionOptionComponent';

type Props = {
  isOpen: boolean;
  onCancelClick: () => void;
  onAddClick: (result: TaskUserFacingParam) => void;
};

const AddTaskParamDialog = ({ isOpen, onCancelClick, onAddClick }: Props) => {
  const [paramName, setParamName] = useState('');
  const [defaultParamValue, setDefaultParamValue] = useState('');
  const [paramType, setParamType] = useState<UserFacingParamType>('string');
  const [isRequired, setIsRequired] = useState(true);
  const [useSelection, setUseSelection] = useState(false);
  const [showSelectionOption, setShowSelectionOption] = useState(false);
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  const handleCancelClick = () => {
    onCancelClick();
  };

  const removeSelectOption = (id: string) => {
    const tempParams = selectOptions.filter((option) => option.id !== id);
    setSelectOptions(tempParams);
  };

  const addSelectOption = (name: string) => {
    const selectOption: SelectOption = {
      id: nanoid(),
      name: name
    };
    setSelectOptions([...selectOptions, selectOption]);
  };

  const handleAddClick = () => {
    const resultObject: TaskUserFacingParam = {
      _id: nanoid(),
      hasSelectOptions: useSelection,
      paramName: paramName,
      defaultParamValue: defaultParamValue,
      paramType: paramType,
      slug: slugify(paramName),
      isRequired: isRequired
    };

    if (useSelection == true) {
      resultObject.selectOptions = selectOptions;
    }
    onAddClick(resultObject);
  };

  const changeParamType = (type: UserFacingParamType) => {
    if (type === 'boolean') {
      setShowSelectionOption(false);
    }
    setShowSelectionOption(true);
    setParamType(type);
  };

  function slugify(str: string) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeiiiioooouuuunc------';

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '_') // collapse whitespace and replace by -
      .replace(/-+/g, '_'); // collapse dashes

    return str;
  }

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>Add Parameter</DialogTitle>
      <DialogContent>
        {/* Param name */}
        <div>
          <label className="block text-md font-medium text-black">Param Name</label>
          <div className="mt-1">
            <input
              type="text"
              name="price"
              id="param name"
              required
              className=" mt-1 block w-full sm:text-sm border border-border-color rounded-md p-2"
              placeholder="eg. User ID"
              onChange={(e) => {
                setParamName(e.target.value);
              }}
            />
          </div>
        </div>

        {/* Param Type */}
        <div className="w-full mt-2">
          <div className="mb-3 ">
            <label className="block text-md font-medium text-black mb-1">Type</label>
            <select
              className="form-select appearance-none  block w-full  px-3 py-1.5 bg-white  border border-solid border-gray-300 rounded-md"
              value={paramType}
              onChange={(e) => changeParamType(e.target.value as UserFacingParamType)}>
              <option value="boolean">boolean</option>
              <option value="string">string</option>
              <option value="number">number</option>
            </select>
          </div>
        </div>

        {/* Default value */}
        <div className="mt-2">
          <label className="block text-md font-medium text-black">Default value</label>
          <div className="mt-1">
            <input
              type="text"
              name="defaultPararmValue"
              id="defaultPararmValue"
              className=" mt-1 block w-full sm:text-sm border border-border-color rounded-md p-2"
              placeholder="eg. USR2312983b21k"
              onChange={(e) => {
                setDefaultParamValue(e.target.value);
              }}
            />
          </div>
        </div>

        {/* Required */}
        <div className="mt-2 items-center pt-2 pb-2">
          <div className="flex flex-row justify-between items-center">
            <label className="text-md font-medium text-black items-start">Required</label>
            <div className="items-end ">
              <Switch value={isRequired} onChange={(e) => setIsRequired(e.target.checked)}></Switch>
            </div>
          </div>
        </div>

        {/* Select options required */}
        {showSelectionOption && (
          <div className="mt-2 flex flex-row justify-between pt-2 pb-2">
            <div className="flex flex-col items-start">
              <label className="block text-md font-medium text-black">Select options</label>
              <label className="text-sm text-gray">
                Require user to pick the value from a list of options
              </label>
            </div>

            <div className="items-end">
              <SwitchComponent
                defaultValue={useSelection}
                onChange={(checked) => setUseSelection(checked)}
              />
            </div>
          </div>
        )}

        {/* Options list */}
        {useSelection &&
          selectOptions.map((option) => (
            <div className="flex flex-row justify-between mt-2 mb-2 p-1" key={option.id}>
              <label> {option.name} </label>
              <button
                className="rounded bg-white border border-red text-red px-2 py-1 hover:bg-opacity-40 hover:bg-red hover:text-white hover:border-opacity-40"
                onClick={() => removeSelectOption(option.id)}>
                Remove
              </button>
            </div>
          ))}

        {useSelection && (
          <AddSelectionOptionComponent onAddClick={(name) => addSelectOption(name)} />
        )}
      </DialogContent>
      <DialogActions>
        <div className="flex flex-col justify-evenly gap-4 w-full">
          <button
            className="font-medium uppercase bg-primary-dark text-white px-3 py-1 shadow-sm rounded w-full"
            onClick={handleAddClick}>
            Add
          </button>
          <button
            className="font-medium uppercase px-3 py-1 shadow-sm rounded text-primary-light w-full"
            onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

AddTaskParamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired
};

export default AddTaskParamDialog;
